@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --gray-bg-color: #ededed;
  --black-bg-color: #000;
  --text-color: #292929;
  --text-black: #000;
  --text-white: #ffff;
  --pink-bg-color: #d090dc;
  --red-collor: #EF4444;
  --primary: #0053e4;
  --secondary: #ff4ec8;
  --critical: #ea6d6f;
  --success: #78cf9f;
  --warning: #f1ca45;

  --background-default: #eceff1;
  --background-shadow: #191c32;

  --surface-default: #ffffff;
  --surface-neutral: #f4f8fb;
  --surface-primary: #ebf0fc;
  --surface-secondary: #ffe0f5;
  --surface-critical: #fdeff0;
  --surface-success: #e4f5ec;
  --surface-warning: #fcf4da;
  --surface-off: #f7f6f6;

  --action-primary: #0053e4;
  --action-primary-light: #7ea5f5;
  --action-primary-dark: #003eab;
  --action-secondary: #ff4ec8;
  --action-secondary-light: #fda4e1;
  --action-secondary-dark: #bf3b96;
  --action-critical: #ea6d6f;
  --action-critical-light: #f3b4b5;
  --action-critical-dark: #af5253;
  --action-warning: #f1ca45;
  --action-warning-light: #f6e3a0;
  --action-warning-dark: #d3b13c;
  --action-success: #78cf9f;
  --action-on: #ffffff;
  --action-off: #c3ccd5;
  --action-disable: #c3ccd5;
  --action-dark: #1c222b;
 
  --text-default: #233043;
  --text-sub: #848b94;
  --text-primary: #0053e4;
  --text-primary-light: #7ea5f5;
  --text-primary-dark: #003eab;
  --text-secondary: #ff4ec8;
  --text-critical: #ea6d6f;
  --text-success: #78cf9f;
  --text-warning: #f1ca45;
  --text-disable: #d3d3d3;
  --text-on: #ffffff;

  --icon-default: #233043;
  --icon-neutral: #828b95;
  --icon-primary: #0053e4;
  --icon-primary-light: #7ea5f5;
  --icon-primary-dark: #003eab;
  --icon-secondary: #ff4ec8;
  --icon-critical: #ea6d6f;
  --icon-success: #78cf9f;
  --icon-warning: #f1ca45;
  --icon-disable: #dddddd;
  --icon-on: #ffffff;

  --border-default: #edeff1;
  --border-neutral: #f4f8fb;
  --border-primary: #0053e4;
  --border-primary-light: #7ea5f5;
  --border-primary-dark: #003eab;
  --border-secondary: #ff4ec8;
  --border-critical: #ea6d6f;
  --border-success: #78cf9f;
  --border-warning: #f1ca45;
  --border-disable: #dddddd;

  --z-index-sticky: 1020;
  --z-index-fixed: 1030;
  --z-index-modal-backdrop: 1040;
  --z-index-offcanvas: 1050;
  --z-index-modal: 1060;
  --z-index-mask: 1061;
  --z-index-dropdown: 999999999;
  --z-index-popover: 1070;
  --z-index-tooltip: 1080;
  --z-index-lazy-load: 1090;
 
  --border-radius: 4px;

  --padding-default: 16px;
  --padding-row: 24px;

  --transition-slow: all ease-out 1s;
  --transition-default: all ease-out 0.6s;
  --transition-fast: all ease-out 0.2s;
  --transition-second-slow: 0.3s;
  --transition-second-default: 0.6s;
  --transition-second-fast: 1s;

  --font-circular-std: 'Circular Std';
  --font-circular-std-book: 'Circular Std Book';
}
.video-list-component {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  .video-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .video-item iframe {
    width: 100%;
    height: 200px;
  }
  .video-item h3 {
    text-align: center;
    margin: 10px 0 0 0;
    font-size: 1.1rem;
  }
}

.form-container {
  margin-left: 10px;
  margin-top: 10px;
  border-radius: var(--border-radius);
  border: 3px solid var(--border-default);
}

.black-header {
background: var(--action-dark);
position: fixed;
top: 0;
width: 100%;
padding: 2px;
display: flex;
align-items: center;
justify-content: space-between;
}


.primary-button {
  background-size: 100% 100%;

  border-radius: var(--border-radius);
  border: 2px solid transparent;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 10px 14px;
  background-color: var(--action-primary);
  color: var(--text-on);
}

.title {
  margin-left: 10px;
  font-size: 32px;
  color: var(--text-color);
}

.subtle-button {
  background-size: 100% 100%;

  border-radius: var(--border-radius);
  border: 2px solid transparent;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 10px 14px;
  &:hover {
    background: var(--gray-bg-color);
  }

}

.label-container {
  display: flex;
  gap: 4px;
  flex-direction: column;
  padding: 4px;
  label {
    font-size: 14px;
    font-weight: bold;
  }
  input[type="text"] {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: var(--text-on);
    border-radius: var(--border-radius);
    border: 2px solid var(--border-default);
    width: 100%;
    height: 100%;
    padding: 7px 12px;
  }
}